import React, { useEffect, useState } from "react";
import "./style.css";
import {
  formatarDataHoraBrasilia,
  normalizeString,
} from "../../../components/Funcoes";
import {
  adicionarRegistroAutorizacaoSaida,
  autorizarSaida,
  obterPessoasComSaidaNaoAutorizada,
} from "../../../api/requisicoes/registroAcessos";
import { useAlert } from "../../../context/AlertContext";
import InfoSemRegistro from "../../../components/InfoSemRegistro";
import { buscarPessoasCadastradas } from "../../../api/requisicoes/pessoas";
import { useConfirm } from "../../../context/ConfirmContext";

import Loading from "../../../components/Loading";

const AutorizacaoSaida = () => {
  const [pessoas, setPessoas] = useState([]);
  const [pessoasCadastradas, setPessoasCadastradas] = useState([]);
  const [filtro, setFiltro] = useState("");
  const [modalAdicionarRegistroOpen, setModalAdicionarRegistroOpen] =
    useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [nomeCompleto, setNomeCompleto] = useState("");
  const [cpf, setCpf] = useState("");
  const [perfil, setPerfil] = useState("");
  const [idPessoa, setIdPessoa] = useState("");
  const [placa, setPlaca] = useState("");
  const [observacoes, setObservacoes] = useState("");
  const [buscaModal, setBuscaModal] = useState("");
  const [erro, setErro] = useState("");

  const [isForeign, setIsForeign] = useState(false);

  const { showAlert } = useAlert();
  const { showConfirm } = useConfirm(); // Use o hook useConfirm

  const [loading, setLoading] = useState(false);

  const userType = localStorage.getItem("userType");

  const fetchData = async () => {
    setLoading(true); // Ativa o carregamento
    try {
      const userId = localStorage.getItem("userId");
      const data = await obterPessoasComSaidaNaoAutorizada(userId);
      setPessoas(data);
    } catch (error) {
      console.error("Erro ao obter pessoas não autorizadas:", error);
    } finally {
      setLoading(false); // Desativa o carregamento
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  function limparString(str) {
    return str.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "").trim(); // Remove apenas caracteres especiais
  }

  function verificarCpf(str) {
    const cpfLimpo = limparString(str);

    if (cpfLimpo.length !== 11 || !/^\d+$/.test(cpfLimpo)) {
      return false;
    } else {
      return true;
    }
  }

  useEffect(() => {
    const obterPessoasCadastradas = async () => {
      setLoading(true); // Ativa o carregamento
      try {
        const dados = await buscarPessoasCadastradas();
        setPessoasCadastradas(dados);
      } catch (error) {
        setErro("Erro ao buscar pessoas: " + error.message);
      } finally {
        setLoading(false); // Desativa o carregamento
      }
    };

    obterPessoasCadastradas();
  }, []);

  const destinationId = parseInt(localStorage.getItem("destinationId"), 10);

  // Função para formatar CPF automaticamente
  const formatCPF = (value) => {
    value = value.replace(/\D/g, ""); // Remove tudo que não for número
    value = value.slice(0, 11); // Limita a 11 dígitos

    // Aplica a máscara do CPF (XXX.XXX.XXX-XX)
    if (value.length > 9) {
      value = value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, "$1.$2.$3-$4");
    } else if (value.length > 6) {
      value = value.replace(/^(\d{3})(\d{3})(\d{0,3})/, "$1.$2.$3");
    } else if (value.length > 3) {
      value = value.replace(/^(\d{3})(\d{0,3})/, "$1.$2");
    }

    return value;
  };

  const pessoasFiltradas = pessoas.filter((pessoa) => {
    const nome = normalizeString(pessoa.nomeCompleto);
    const responsavel = normalizeString(pessoa.nomeResponsavel);
    const filtroNormalized = normalizeString(filtro);

    return (
      nome.includes(filtroNormalized) || responsavel.includes(filtroNormalized)
    );
  });

  const handleAutorizarSaida = (idRegistro, nomeCompleto) => {
    showConfirm(
      `Deseja autorizar a saída de ${nomeCompleto}?`,
      async () => {
        try {
          setLoading(true);
          await autorizarSaida(
            idRegistro,
            localStorage.getItem("fullName").toUpperCase()
          );
          showAlert("Autorização realizada com sucesso!", "sucesso");
          fetchData();
        } catch (error) {
          if (error.response) {
            showAlert(`Error! ${error.response.data.error}.`, "erro");
          } else {
            showAlert(
              "Erro desconhecido, contate o administrador do sistema!",
              "erro"
            );
          }
        } finally {
          setLoading(false);
        }
      },
      () => {
        // Ação para o botão "Cancelar", se necessário
      }
    );
  };

  const handleAdicionarRegistro = () => {
    setModalOpen(true);
  };

  const handleNovaAutorizacaoSaida = (e) => {
    e.preventDefault();

    const novoRegistro = {
      observacoes,
      placaVeiculo: placa,
      responsavel: localStorage.getItem("fullName").toUpperCase(),
      idPessoa,
      nomeAutorizador: localStorage.getItem("fullName").toUpperCase(),
    };

    showConfirm(
      `Deseja autorizar a saída de ${nomeCompleto}?`,
      async () => {
        try {
          setLoading(true);
          await adicionarRegistroAutorizacaoSaida(novoRegistro);

          showAlert("Autorização realizada com sucesso!", "sucesso");

          // Resetar os campos do formulário
          setNomeCompleto("");
          setCpf("");
          setPerfil("");
          setPlaca("");
          setObservacoes("");
          setModalAdicionarRegistroOpen(false);
        } catch (error) {
          if (error.response) {
            showAlert(`Error! ${error.response.data.error}.`, "erro");
          } else {
            showAlert(
              "Erro desconhecido, contate o administrador do sistema!",
              "erro"
            );
          }
          // Resetar os campos do formulário mesmo em caso de erro
          setNomeCompleto("");
          setCpf("");
          setPerfil("");
          setPlaca("");
          setObservacoes("");
        } finally {
          setLoading(false);
        }
      },
      () => {
        // Ação para o botão "Cancelar", se necessário
      }
    );
  };

  const pessoasFiltradasModal = pessoasCadastradas.filter(
    (pessoa) =>
      normalizeString(pessoa.fullName).includes(normalizeString(buscaModal)) ||
      pessoa.cpf.toLowerCase().includes(buscaModal.toLowerCase())
  );

  const selecionarPessoa = (pessoa) => {
    setCpf(pessoa.cpf);
    setNomeCompleto(pessoa.fullName.toUpperCase());
    setPerfil(pessoa.profile.profileType.toUpperCase());
    setIdPessoa(pessoa.id);
    setModalOpen(false);
    setModalAdicionarRegistroOpen(true);
  };

  return (
    <div className="tela">
      {loading && <Loading />} {/* Mostra o carregamento quando necessário */}
      <div className="autorizacao-saida">
        <div className="header-autorizarSaida">
          <div className="headerEsquerda-autorizarSaida">
            <h1>Autorização de Saída</h1>
          </div>
          <div className="headerDireita-AutorizarSaida">
            <div className="espacobotao-adicionarRegistro">
              {(userType === "4" || userType === "3") && (
                <button
                  onClick={handleAdicionarRegistro}
                  className="botao-adicionarRegistro-autorizarSaida"
                >
                  Adicionar Registro
                </button>
              )}
            </div>
            <div className="filtrar-nome-autorizarSaida">
              <div className="form-group">
                <input
                  type="text"
                  placeholder="Filtrar por nome ou responsável..."
                  value={filtro}
                  onChange={(e) => setFiltro(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
        {pessoasFiltradas.length > 0 ? (
          <table>
            <thead>
              <tr>
                <th>Nome Completo</th>
                <th>Placa</th>
                <th>Responsável</th>
                <th>Data/Hora de Entrada</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {pessoasFiltradas.map((pessoa) => (
                <tr key={pessoa.idRegistro}>
                  <td>{pessoa.nomeCompleto}</td>
                  <td>{pessoa.placaVeiculo}</td>
                  <td>{pessoa.nomeResponsavel}</td>
                  <td>{formatarDataHoraBrasilia(pessoa.dataHoraEntrada)}</td>
                  <td id="autorizar">
                    <button
                      onClick={() =>
                        handleAutorizarSaida(
                          pessoa.idRegistro,
                          pessoa.nomeCompleto
                        )
                      }
                    >
                      Autorizar Saída
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <InfoSemRegistro />
        )}
      </div>
      {modalOpen && (
        <div className="modal">
          <div className="modal-content">
            <div className="header-modal">
              <div className="modal-titulo">
                <h2>Selecionar Pessoa</h2>
              </div>
              <div className="modal-filtro">
                <input
                  type="text"
                  placeholder="Buscar por nome ou CPF..."
                  value={buscaModal}
                  onChange={(e) => {
                    const valorLimpo = e.target.value.replace(
                      /[^a-zA-ZÀ-ÖØ-öø-ÿ0-9\s]/g,
                      ""
                    ); // Remove apenas caracteres especiais
                    setBuscaModal(valorLimpo);
                  }}
                  className="search-input"
                />
              </div>
            </div>
            <div className="modal-table-container">
              {pessoasFiltradasModal.length > 0 ? (
                <table className="tabela-clicavel">
                  <thead>
                    <tr>
                      <th>Documento</th>
                      <th>Nome Completo</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pessoasFiltradasModal.map((pessoa, index) => (
                      <tr
                        key={index}
                        onClick={() =>
                          !pessoa.blocked && selecionarPessoa(pessoa)
                        }
                        className={pessoa.blocked ? "linha-bloqueada" : ""}
                      >
                        <td>
                          {verificarCpf(pessoa.cpf)
                            ? formatCPF(pessoa.cpf)
                            : pessoa.cpf}
                        </td>
                        <td>{pessoa.fullName}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <InfoSemRegistro />
              )}
            </div>
            <button
              id="pesquisarPessoas-button-fechar"
              onClick={() => setModalOpen(false)}
            >
              Fechar
            </button>
          </div>
        </div>
      )}
      {modalAdicionarRegistroOpen && (
        <div className="modal">
          <div className="modal-content-registrar-autorizacao">
            <div className="modal-titulo-registrar">
              <h2>Registrar Autorização de Saída</h2>
            </div>
            <div className="modalEditar-formulario">
              <form onSubmit={handleNovaAutorizacaoSaida}>
                <div className="form-group">
                  <label htmlFor="nomeCompleto">Nome Completo:</label>
                  <input
                    type="text"
                    id="nomeCompleto"
                    name="nomeCompleto"
                    value={nomeCompleto}
                    onChange={(e) => setNomeCompleto(e.target.value)}
                    onBlur={(e) =>
                      setNomeCompleto(e.target.value.toUpperCase())
                    }
                    required
                    readOnly
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="cpf">CPF:</label>
                  <input
                    type="text"
                    id="cpf"
                    name="cpf"
                    value={cpf}
                    onChange={(e) => setCpf(e.target.value)}
                    required
                    readOnly
                  />
                </div>

                <div className="form-group">
                  <label>Perfil:</label>
                  <input
                    type="text"
                    value={perfil}
                    onChange={(e) => setPerfil(e.target.value.toUpperCase())}
                    required
                    readOnly
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="placa">Placa do Veículo:</label>
                  <input
                    type="text"
                    id="placa"
                    name="placa"
                    value={placa}
                    onChange={(e) => setPlaca(e.target.value)}
                    onBlur={(e) => setPlaca(e.target.value.toUpperCase())}
                    required
                    tabIndex="1"
                  />
                </div>
                <div className="form-observacoes-modal">
                  <label htmlFor="observacoes">Observações:</label>
                  <textarea
                    type="text"
                    className="input-observacoes"
                    value={observacoes}
                    onChange={(e) => setObservacoes(e.target.value)}
                    onBlur={(e) => setObservacoes(e.target.value.toUpperCase())}
                    tabIndex="2"
                  />
                </div>
                <button
                  type="submit"
                  id="registrarAutorizacao-botao-confirmar"
                  tabIndex="3"
                >
                  Confirmar
                </button>
              </form>
              <button
                id="registrarAutorizacao-botao-fechar"
                onClick={() => setModalAdicionarRegistroOpen(false)}
                tabIndex="4"
              >
                Fechar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AutorizacaoSaida;
