// src/pages/CadastroPessoa.js

import React, { useState } from "react";
import "./style.css";
import Webcam from "react-webcam";
import { FaCamera, FaUpload } from "react-icons/fa";
import Perfil from "../../../components/Perfil";
import avatarPerfil from "../../../assets/avatar-de-perfil.png";
import { adicionarCadastroPessoa } from "../../../api/requisicoes/pessoas";
import { useNavigate } from "react-router-dom";
import { useAlert } from "../../../context/AlertContext";
import Loading from "../../../components/Loading";

function CadastroPessoa() {
  const [nome, setNome] = useState("");
  const [perfil, setPerfil] = useState({ profileType: "", profileId: "" });
  const [cpf, setCpf] = useState("");
  const [foto, setFoto] = useState(avatarPerfil);
  const [usandoWebcam, setUsandoWebcam] = useState(false);
  const [cameraPermitida, setCameraPermitida] = useState(false);
  const [cameraErro, setCameraErro] = useState(null);
  const { showAlert } = useAlert();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [previewUrl, setPreviewUrl] = useState(null);

  function limparString(str) {
    return str.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "").trim(); // Remove apenas caracteres especiais
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (foto === avatarPerfil) {
      showAlert("É necessário adicionar uma foto!", "erro");
      return;
    }

    if (!isForeign && limparString(cpf).length !== 11) {
      showAlert("CPF deve possuir 11 dígitos!", "erro");
      return;
    }

    const novaPessoa = {
      cpf: limparString(cpf),
      nomeCompleto: limparString(nome),
      perfilId: perfil.profileId, // Use profileId aqui
      foto,
    };

    try {
      setLoading(true);
      await adicionarCadastroPessoa(novaPessoa);
      showAlert("Pessoa cadastrada com sucesso!", "sucesso");
      setNome("");
      setCpf("");
      setPerfil({ profileType: "", profileId: "" });
      setFoto(avatarPerfil);
      navigate("/registro-entrada");
    } catch (error) {
      if (error.response) {
        showAlert(`Erro! ${error.response.data.error}.`, "erro");
      } else {
        showAlert(
          "Erro desconhecido, contate o administrador do sistema!",
          "erro"
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFoto(file);
    const previewUrl = URL.createObjectURL(file);
    setPreviewUrl(previewUrl);
    setUsandoWebcam(false);
  };

  const handleCapture = () => {
    const screenshot = webcamRef.current.getScreenshot();
    const byteString = atob(screenshot.split(",")[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: "image/jpeg" });
    const file = new File([blob], "webcam.jpg", { type: "image/jpeg" });
    setFoto(file);
    setPreviewUrl(URL.createObjectURL(file));
    setUsandoWebcam(false);
  };

  const webcamRef = React.useRef(null);

  const handleUsarWebcam = () => {
    navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        const videoDevices = devices.filter(
          (device) => device.kind === "videoinput"
        );
        if (videoDevices.length === 0) {
          throw new Error("Nenhuma câmera encontrada no dispositivo.");
        }
        return navigator.mediaDevices.getUserMedia({ video: true });
      })
      .then((stream) => {
        setCameraPermitida(true);
        setCameraErro(null);
        setUsandoWebcam(true);
      })
      .catch((error) => {
        console.error("Erro ao acessar a câmera:", error);
        setCameraPermitida(false);
        setCameraErro(error);
        setUsandoWebcam(false);
      });
  };

  const [isForeign, setIsForeign] = useState(false);

  // Função para formatar CPF automaticamente
  const formatCPF = (value) => {
    value = value.replace(/\D/g, ""); // Remove tudo que não for número
    value = value.slice(0, 11); // Limita a 11 dígitos

    // Aplica a máscara do CPF (XXX.XXX.XXX-XX)
    if (value.length > 9) {
      value = value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, "$1.$2.$3-$4");
    } else if (value.length > 6) {
      value = value.replace(/^(\d{3})(\d{3})(\d{0,3})/, "$1.$2.$3");
    } else if (value.length > 3) {
      value = value.replace(/^(\d{3})(\d{0,3})/, "$1.$2");
    }

    return value;
  };

  const handleCpfChange = (e) => {
    let value = e.target.value;

    if (!isForeign) {
      value = formatCPF(value);
    }

    setCpf(value);
  };

  return (
    <div className="tela">
      {loading && <Loading />}
      <div className="cadastro-pessoa">
        <form onSubmit={handleSubmit}>
          <div className="painel">
            <div className="esquerda">
              <h1>Cadastro de Pessoa</h1>
              <div className="form-group">
                <label>Nome:</label>
                <input
                  type="text"
                  value={nome}
                  onChange={(e) => setNome(e.target.value)}
                  onBlur={(e) => setNome(e.target.value.toUpperCase())}
                  tabIndex={"1"}
                  required
                />
              </div>
              <div className="form-group">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <label>CPF (somente números):</label>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <input
                      type="checkbox"
                      id="estrangeiro"
                      style={{ marginBottom: "8px" }}
                      checked={isForeign}
                      onChange={() => {
                        setIsForeign(!isForeign);
                        setCpf(""); // Limpa o input ao mudar o tipo
                      }}
                    />
                    <label
                      htmlFor="estrangeiro"
                      style={{ whiteSpace: "nowrap", marginRight: "10px" }}
                    >
                      Outro Documento
                    </label>
                  </div>
                </div>
                <input
                  type="text"
                  value={cpf}
                  onChange={handleCpfChange}
                  placeholder={
                    isForeign ? "Digite o documento" : "XXX.XXX.XXX-XX"
                  }
                  tabIndex="2"
                  required
                />
              </div>

              <Perfil
                value={perfil}
                onChange={(value) => setPerfil(value)}
                required
                tabIndex={3}
                className={"input-perfil"}
              />
            </div>

            <div className="direita">
              <div className="foto-moldura">
                {usandoWebcam && cameraPermitida ? (
                  <Webcam
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    className="webcam"
                  />
                ) : (
                  <img
                    src={previewUrl || avatarPerfil}
                    alt="Foto Preview"
                    className="foto-preview"
                  />
                )}
              </div>
              <div className="foto-options">
                <button
                  type="button"
                  onClick={usandoWebcam ? handleCapture : handleUsarWebcam}
                >
                  <FaCamera />{" "}
                  {usandoWebcam ? "Capturar Foto" : "Utilizar Webcam"}
                </button>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                />
              </div>
              {cameraErro && (
                <div className="camera-error">
                  <p>Erro ao acessar a câmera: {cameraErro.message}</p>
                </div>
              )}
            </div>
          </div>

          <div className="botao-cadastrar">
            <button type="submit" tabIndex={"4"} className="botao-interno">
              Cadastrar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CadastroPessoa;
