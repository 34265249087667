import React, { useEffect, useState } from "react";
import Webcam from "react-webcam";
import { FaCamera } from "react-icons/fa";
import Loading from "../../../../components/Loading";
import { useAlert } from "../../../../context/AlertContext";
import { editarCadastroPessoa } from "../../../../api/requisicoes/pessoas";

import "../style.css";
import Perfil from "../../../../components/Perfil";

const ModalEditarPessoa = ({
  pessoa,
  fecharModal,
  setNomeCompleto,
  setCpf,
  setFoto,
  setPerfil,
  setPerfilId,
  setIdPessoa,
  setAtualizar,
  atualizar,
}) => {
  const idPessoa = pessoa.idPessoa;
  const [nomeCompletoEditado, setNomeCompletoEditado] = useState(
    pessoa.nomeCompleto
  );
  const [cpfEditado, setCpfEditado] = useState(limparString(pessoa.cpf));
  const [perfilEditado, setPerfilEditado] = useState(pessoa.perfil);
  const [perfilIdEditado, setPerfilIdEditado] = useState(pessoa.perfilId);
  const [fotoEditada, setFotoEditada] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(pessoa.caminhoImagem);
  const [loading, setLoading] = useState(false);
  const { showAlert } = useAlert();
  const webcamRef = React.useRef(null);

  const [senhaEditar, setSenhaEditar] = useState("");
  const [senhaCorreta, setSenhaCorreta] = useState(false);
  const [erroSenha, setErroSenha] = useState("");

  const [usandoWebcam, setUsandoWebcam] = useState(false);
  const [cameraPermitida, setCameraPermitida] = useState(false);
  const [cameraErro, setCameraErro] = useState(null);

  const [isForeign, setIsForeign] = useState(false);

  function limparString(str) {
    return str.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "").trim(); // Remove apenas caracteres especiais
  }

  useEffect(() => {
    const cpfLimpo = limparString(pessoa.cpf);

    if (cpfLimpo.length !== 11 || !/^\d+$/.test(cpfLimpo)) {
      setIsForeign(true);
    } else {
      setIsForeign(false);
    }
  }, [pessoa.cpf]); // Dispara quando pessoa.cpf mudar

  // Função para formatar CPF automaticamente
  const formatCPF = (value) => {
    value = value.replace(/\D/g, ""); // Remove tudo que não for número
    value = value.slice(0, 11); // Limita a 11 dígitos

    // Aplica a máscara do CPF (XXX.XXX.XXX-XX)
    if (value.length > 9) {
      value = value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, "$1.$2.$3-$4");
    } else if (value.length > 6) {
      value = value.replace(/^(\d{3})(\d{3})(\d{0,3})/, "$1.$2.$3");
    } else if (value.length > 3) {
      value = value.replace(/^(\d{3})(\d{0,3})/, "$1.$2");
    }

    return value;
  };

  const handleCpfChange = (e) => {
    let value = e.target.value;

    if (!isForeign) {
      value = formatCPF(value);
    }

    setCpfEditado(value);
  };

  const finalizarEdicao = () => {
    setCpf(limparString(cpfEditado));
    setNomeCompleto(nomeCompletoEditado.toUpperCase());
    setPerfil(perfilEditado.toUpperCase());
    setPerfilId(perfilIdEditado);
    setIdPessoa(idPessoa);
    setFoto(previewUrl);
  };

  const handleEditarPessoa = async (e) => {
    e.preventDefault();

    if (!isForeign && limparString(cpfEditado).length !== 11) {
      showAlert("CPF deve possuir 11 dígitos!", "erro");
      return;
    }

    // Criar objeto pessoaEditada com dados que serão enviados ao back-end
    const pessoaEditada = {
      idPessoa,
      nomeCompleto: limparString(nomeCompletoEditado),
      cpf: limparString(cpfEditado),
      perfilId: perfilIdEditado,
      foto: fotoEditada, // Enviar o objeto File diretamente
    };

    try {
      setLoading(true);
      await editarCadastroPessoa(pessoaEditada);
      showAlert("Pessoa editada com sucesso!", "sucesso");
      finalizarEdicao();
      setAtualizar(!atualizar);
      fecharModal();
    } catch (error) {
      if (error.response) {
        showAlert(`Erro! ${error.response.data.error}.`, "erro");
      } else {
        showAlert(
          "Erro desconhecido, contate o administrador do sistema!",
          "erro"
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFotoEditada(file);
    setPreviewUrl(URL.createObjectURL(file));
  };

  const handleCapture = () => {
    const screenshot = webcamRef.current.getScreenshot();
    const byteString = atob(screenshot.split(",")[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: "image/jpeg" });
    const file = new File([blob], "webcam.jpg", { type: "image/jpeg" });
    setFotoEditada(file);
    setPreviewUrl(URL.createObjectURL(file));
  };

  const handleUsarWebcam = () => {
    navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        const videoDevices = devices.filter(
          (device) => device.kind === "videoinput"
        );
        if (videoDevices.length === 0) {
          throw new Error("Nenhuma câmera encontrada no dispositivo.");
        }
        return navigator.mediaDevices.getUserMedia({ video: true });
      })
      .then((stream) => {
        setCameraPermitida(true);
        setCameraErro(null);
        setUsandoWebcam(true);
      })
      .catch((error) => {
        console.error("Erro ao acessar a câmera:", error);
        setCameraPermitida(false);
        setCameraErro(error);
        setUsandoWebcam(false);
      });
  };

  const verificarSenha = () => {
    if (senhaEditar === "Edicao.123") {
      setSenhaCorreta(true);
      setErroSenha("");
    } else {
      setErroSenha("Senha incorreta! Tente novamente.");
    }
  };

  return (
    <div className="modal">
      {loading && <Loading />}
      {!senhaCorreta ? (
        <div className="modal-senha-editar">
          <button className="close-button" onClick={() => fecharModal()}>
            ✖
          </button>
          <div className="input-modal-senha">
            <label>Senha:</label>
            <input
              type="password"
              id="senhaEditar"
              name={`senha-${Math.random()}`} // Nome aleatório para evitar salvamento
              value={senhaEditar}
              onChange={(e) => setSenhaEditar(e.target.value)}
              required
              autoComplete="new-password" // Outra alternativa para evitar preenchimento automático
            />

            <button id="botao-senha-editar" onClick={verificarSenha}>
              Confirmar
            </button>
          </div>
          {erroSenha && <p className="erro-senha">{erroSenha}</p>}
        </div>
      ) : (
        <div className="modalEditar-content">
          <form onSubmit={handleEditarPessoa}>
            <div className="modalEditar-conteudo">
              <div className="modalEditar-formulario">
                <div className="modalEditar-titulo">
                  <h2>Editar Pessoa</h2>
                </div>
                <div className="form-group">
                  <label htmlFor="nomeCompleto">Nome Completo:</label>
                  <input
                    type="text"
                    id="nomeCompleto"
                    name="nomeCompleto"
                    value={nomeCompletoEditado}
                    onChange={(e) => setNomeCompletoEditado(e.target.value)}
                    onBlur={(e) =>
                      setNomeCompletoEditado(e.target.value.toUpperCase())
                    }
                    required
                  />
                </div>

                <div className="form-group">
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <label>CPF (somente números):</label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      <input
                        type="checkbox"
                        id="estrangeiro"
                        style={{ marginBottom: "8px" }}
                        checked={isForeign}
                        onChange={() => {
                          setIsForeign(!isForeign);
                          setCpfEditado(""); // Limpa o input ao mudar o tipo
                        }}
                      />
                      <label
                        htmlFor="estrangeiro"
                        style={{ whiteSpace: "nowrap", marginRight: "10px" }}
                      >
                        Outro Documento
                      </label>
                    </div>
                  </div>
                  <input
                    type="text"
                    value={isForeign ? cpfEditado : formatCPF(cpfEditado)}
                    onChange={handleCpfChange}
                    placeholder={
                      isForeign ? "Digite o documento" : "XXX.XXX.XXX-XX"
                    }
                    tabIndex="2"
                    required
                  />
                </div>

                <Perfil
                  value={{
                    profileType: perfilEditado,
                    profileId: perfilIdEditado,
                  }}
                  onChange={(value) => {
                    setPerfilEditado(value.profileType); // Atualiza o perfil editado
                    setPerfilIdEditado(value.profileId); // Atualiza o ID do perfil editado
                  }}
                  required
                />
              </div>

              <div className="modalEditar-enviarImagem">
                <div className="modalEditar-fotoMoldura">
                  {usandoWebcam && cameraPermitida ? (
                    <Webcam
                      audio={false}
                      ref={webcamRef}
                      screenshotFormat="image/jpeg"
                      className="webcam"
                    />
                  ) : (
                    <img
                      src={previewUrl}
                      alt="Foto Preview"
                      className="foto-preview"
                    />
                  )}
                </div>
                <div className="foto-options">
                  <button
                    type="button"
                    onClick={usandoWebcam ? handleCapture : handleUsarWebcam}
                  >
                    <FaCamera />{" "}
                    {usandoWebcam ? "Capturar Foto" : "Utilizar Webcam"}
                  </button>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                  />
                </div>
                {cameraErro && (
                  <div className="camera-error">
                    <p>Erro ao acessar a câmera: {cameraErro.message}</p>
                  </div>
                )}
              </div>
            </div>
            <button type="submit" id="editarPessoa-botao-confirmar">
              Confirmar
            </button>
            <button
              id="editarPessoa-botao-fechar"
              onClick={() => fecharModal()}
            >
              Fechar
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default ModalEditarPessoa;
